<template>
  <ValidationObserver
    ref="selectSchoolRules"
  >
    <b-modal
      id="select-school-modal"
      ref="select-school-modal"
      title="Select school"
      centered
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      size="sm"
      class="p-0"
      @show="setSchoolsList"
      @ok.prevent="selectSchool"
    >
      <b-form-group
        label="School"
      >
        <ValidationProvider
          #default="{ errors }"
          name="School"
          rules="required"
        >
          <v-select
            v-model="selectedShool"
            class="mb-2"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="schoolsList"
            placeholder="Select school"
            :clearable="false"
            label="name"
            :reduce="option => option.id"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import axios from '@axios'
import { BModal, BFormGroup } from 'bootstrap-vue'

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BModal,
    BFormGroup,

    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  data() {
    return {
      isLoading: false,
      selectedShool: null,
      schoolsList: [],
      required,
    }
  },
  methods: {
    async setSchoolsList() {
      try {
        this.isLoading = true
        const response = await axios.get('/auth/schools')
        this.schoolsList = response.data.data
        return response
      } catch (error) {
        return error
      } finally {
        this.isLoading = false
      }
    },
    selectSchool() {
      return new Promise((resolve, reject) => {
        this.$refs.selectSchoolRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.$emit('selectSchool', this.selectedShool)
            this.$bvModal.hide('select-school-modal')
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style>

</style>
