import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useReportDetails() {
  // Use toast
  const toast = useToast()

  const fetchFields = id => axios
    .post(`auth/report-views/${id}/fields`)
    .then(response => response.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Report View fields',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const reportExecute = queryParams => axios
    .post('auth/reports/execute', queryParams)
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Report execution error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const reportExport = queryParams => axios
    .post('auth/reports/export', queryParams)
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Report export error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const fetchReport = ID => axios
    .get(`/auth/reports/${ID}`, { params: { is_default_report: true } })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Report item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const postReport = queryParams => axios
    .post('/auth/reports', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New report item has created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error creating new report',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const updateReport = (ID, queryParams) => axios
    .put(`/auth/reports/${ID}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Report item was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating report',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchFields,
    reportExecute,
    reportExport,
    fetchReport,
    postReport,
    updateReport,
  }
}
