<template>
  <div>
    <b-spinner
      v-if="isLoading"
      class="align-middle"
    />
    <div v-else>
      <h2
        class="mb-2"
      >
        {{ reportName }}
      </h2>
      <b-row>
        <b-col
          cols="3"
        >
          <b-card>
            <div>
              <h5 class="d-flex justify-content-between my-2">
                <span>Column Selection</span>
                <!-- Dropdown -->
                <b-dropdown
                  variant="link"
                  toggle-class="mx-auto p-0"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    @click="selectAll"
                  >
                    <span>Select All</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="deselectAll"
                  >
                    <span>Deselect All</span>
                  </b-dropdown-item>
                </b-dropdown>
              </h5>

              <v-select
                v-model="selectedFields"
                multiple
                class="mb-2"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="reportViewFields"
                placeholder="Select"
                :clearable="false"
                :close-on-select="false"
              >
                <template
                  #selected-option-container="{ option }"
                >
                  <div
                    style="display: none"
                  >{{ option }}</div>
                </template>
              </v-select>

              <draggable
                v-model="selectedFields"
              >
                <b-badge
                  v-for="(field, index) in selectedFields"
                  :key="index"
                  v-b-tooltip.hover.right="field"
                  tag="div"
                  class="d-flex justify-content-between position-relative mb-1 pr-2 text-left text-wrap badge-selected-column"
                  variant="primary"
                >
                  <div class="d-flex align-items-bottom">
                    <img src="@/assets/images/icons/more-vertical.svg">
                    <span class="text-break">{{ field.label | cutString }}</span>
                  </div>
                  <feather-icon
                    icon="XIcon"
                    size="18"
                    class="close-icon cursor-pointer"
                    @click="removeFieldFromSelected(index)"
                  />
                </b-badge>
              </draggable>
            </div>

            <div>
              <h5>Group By</h5>
              <v-select
                v-model="groupBy"
                class="mb-2"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="fieldsOptions"
                :reduce="field => field.value"
                label="text"
                placeholder="Select"
              >
                <template #selected-option="option">
                  <div class="text-break">
                    <strong>{{ option.text }}</strong>
                  </div>
                </template>
              </v-select>
            </div>

            <b-button
              variant="primary"
              class="w-100"
              :disabled="reportViewFields.length === 0"
              @click="$bvModal.show('select-school-modal')"
            >
              Update Table
            </b-button>
          </b-card>
        </b-col>
        <b-col
          cols="9"
        >
          <!-- Table Container Card -->
          <b-card
            no-body
          >

            <div class="table-header m-2">
              <h4 class="mb-2 d-flex align-items-center">
                <span v-if="!reportView">Select Report Type</span>
                <span v-else>{{ reportView.name }}</span>
                <feather-icon
                  icon="EditIcon"
                  size="18"
                  class="ml-1 cursor-pointer"
                  @click="openReportViewModal"
                />
              </h4>
              <!-- Table Top -->
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <p v-if="filters.children > 0">
                    Selected filters
                  </p>
                  <b-badge
                    v-for="(filter, index) in filters.children"
                    :key="index"
                    class="p-1 mr-1 text-primary"
                    variant="light"
                  >
                    {{ filter.query.rule }}
                    <feather-icon
                      icon="Trash2Icon"
                      size="18"
                      class="ml-1 cursor-pointer"
                      @click="removeFilterFromTable(index)"
                    />
                  </b-badge>
                </b-col>
                <!-- Per Page -->
                <b-col
                  v-if="showPerPage"
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >

                  <label class="mr-1 mb-0">Show</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-50 mr-1"
                  />
                </b-col>

                <!-- Search -->
                <b-col
                  cols="12"
                >
                  <div class="d-flex align-items-center justify-content-end">
                    <div
                      v-if="groupedData.length > 0"
                      class="mr-3"
                    >
                      <b-button
                        class="mr-1"
                        variant="outline-primary"
                        @click="collapseAll"
                      >
                        Collapse All
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        @click="expandAll"
                      >
                        Expand All
                      </b-button>
                    </div>

                    <b-button
                      variant="primary"
                      @click="showFilterModal"
                    >
                      Add Filter
                    </b-button>
                  </div>
                </b-col>
              </b-row>

            </div>

            <b-table
              v-if="!isLoading"
              v-show="groupedData.length == 0"
              ref="reportTable"
              responsive
              :items="fetchTableList"
              :busy.sync="isBusy"
              primary-key="id"
              class="position-relative"
            >
              <template #table-busy>
                <div class="my-2">
                  <b-spinner class="align-middle" />
                </div>
              </template>

              <template #head()="data">
                <div
                  v-if="data.column !== 'selected'"
                  class="cursor-pointer"
                  :style="{
                    fontWeight: data.column === sortBy ? '600' : '400',
                  }"
                  @click="sortByColumn(data.column)"
                >
                  {{ data.label | fixTableHeaderView }}
                </div>

                <div
                  v-else
                  :style="{ fontWeight: 400 }"
                >
                  {{ data.label }}
                </div>
              </template>

              <template
                v-for="(slot, index) in tableColumns"
                v-slot:[`cell(${slot})`]="data"
              >
                <span :key="index">
                  <img
                    v-if="checkIfSlotValueIsBase64File(data.item[slot])"
                    class="image-slot"
                    :src="data.item[slot]"
                  >
                  <span v-else> {{ data.item[slot] }} </span>
                </span>
              </template>
            </b-table>

            <template
              v-if="groupedData"
            >
              <div
                ref="groupedTablesContainer"
                class="grouped-tables-container"
              >
                <b-table-simple
                  v-for="(data, index) in groupedData"
                  :key="index"
                  class="mb-3"
                  :style="{ width: getContainerWith() }"
                >
                  <thead
                    :ref="'groupedTableHeader' + index"
                    head-variant="light"
                  >
                    <b-tr>
                      <template
                        v-for="(column, i) in tableColumns"
                      >
                        <b-th
                          v-if="column !== 'selected' && column !== 'uuid'"
                          :key="i"
                        >
                          <span
                            v-if="index == 0"
                            class="w-100 cursor-pointer"
                            :style="{
                              fontWeight: column === sortBy ? '600' : '400',
                            }"
                            @click="sortForGroupedData(column)"
                          >{{ column | fixTableHeaderView }}</span>
                          <span
                            v-else
                            style="fontWeight: 400"
                          >{{ column | fixTableHeaderView }}</span>
                        </b-th>
                      </template>
                    </b-tr>
                  </thead>
                  <b-tbody>
                    <template v-for="(subitem, iSub) in data.grouped">
                      <b-tr
                        v-show="iSub === 0 || !data.collapsed"
                        :key="iSub"
                      >
                        <b-td
                          v-if="iSub === 0"
                          :rowspan="data._total"
                          class="align-top bg-light"
                        >
                          <div
                            class="stiky-rowspan"
                            :style="{
                              top: getHeaderHeight('groupedTableHeader' + index)
                            }"
                          >
                            <div class="stiky-rowspan__value">
                              <span v-if="data.group_by.value">
                                {{ data.group_by.value }}
                              </span>
                              <span v-else> Null </span>
                            </div>
                            <div class="stiky-rowspan__total">
                              Subtotal: {{ data._total }}
                            </div>
                            <div
                              class="cursor-pointer stiky-rowspan__collapse"
                              @click="toggleGroupedTableCollapse(index)"
                            >
                              <feather-icon
                                v-if="data.collapsed"
                                icon="ChevronDownIcon"
                                size="16"
                                class="align-middle text-body"
                              />
                              <feather-icon
                                v-else
                                icon="ChevronUpIcon"
                                size="16"
                                class="align-middle text-body"
                              />
                            </div>
                          </div>
                        </b-td>
                        <template
                          v-for="(value, name, idx) in subitem"
                        >
                          <b-td
                            v-if="name !== 'selected' && name !== 'uuid' && name !== groupByOptions.name"
                            :key="idx"
                          >{{ value }}</b-td>
                        </template>
                      </b-tr>
                    </template>
                  </b-tbody>
                </b-table-simple>
              </div>
            </template>

            <div class="mx-2 mb-2">
              <b-row>

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalItems"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>

              </b-row>

              <b-row>
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-end mt-2"
                >
                  <b-button
                    v-if="reportId"
                    class="ml-1"
                    variant="outline-primary"
                    :disabled="!reportView"
                    @click="openSaveReportModal(true)"
                  >
                    <span class="mr-25 align-middle">Save As</span>
                  </b-button>
                  <b-button
                    class="ml-1"
                    variant="primary"
                    :disabled="!reportView"
                    @click="openSaveReportModal(false)"
                  >
                    <span class="mr-25 align-middle">Save</span>
                  </b-button>
                </b-col>

              </b-row>
            </div>

          </b-card>
        </b-col>
      </b-row>

      <ReportViewListModal
        @setReportView="setReportView"
      />
      <!-- Filters modal -->
      <FiltersModal
        :filters="filters"
        :fields-options="fieldsOptions"
        @removeFilterRow="removeFilterRow"
      />

      <SaveReportModal
        :report-name="reportName"
        :report-type="saveReportType"
        @saveReport="saveReport"
      />

      <SelectSchoolModal
        @selectSchool="selectSchool"
      />
    </div>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, BButton, BBadge, BSpinner,
  BTableSimple, BTbody, BTr, BTh, BTd, VBTooltip, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

import draggable from 'vuedraggable'

import vSelect from 'vue-select'
import store from '@/store'
import useReportDetails from '@/views/admin/reports-v2/reports/report-details/useReportDetails'
import ReportViewListModal from '@/views/admin/reports-v2/reports/report-details/report-view-list-modal/ReportViewListModal.vue'
import FiltersModal from '@/views/admin/reports-v2/reports/report-details/filters-modal/FiltersModal.vue'
import SaveReportModal from '@/views/admin/reports-v2/reports/report-details/save-report-modal/SaveReportModal.vue'
import SelectSchoolModal from '@/views/admin/reports-v2/reports/report-details/select-school-modal/SelectSchoolModal.vue'

import dynamicSort from '@/helpers/sortBy'

const getDefaultFilters = () => ({
  children: [],
  logicalOperator: 'and',
})
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BButton,
    BBadge,
    BSpinner,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BDropdown,
    BDropdownItem,

    vSelect,
    draggable,

    FiltersModal,
    ReportViewListModal,
    SaveReportModal,
    SelectSchoolModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    cutString(val) {
      let string = val
      if (val) {
        if (string.length > 38) {
          string = `${string.slice(0, 35)} ...`
        }
      }
      return string
    },
    fixTableHeaderView(val) {
      val.split()
      return val.split('.').join(': ').replace(/_/g, ' ')
    },
  },
  data() {
    return {
      perPage: 10,
      totalItems: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      sortBy: null,
      isSortDirDesc: true,
      reportId: this.$router.currentRoute.query?.id,
      reportName: '',
      reportView: null,
      reportViewFields: [],
      selectedFields: [],
      dataSourceList: [],
      fieldsOptions: [],
      filterSelected: null,
      filters: getDefaultFilters(),
      isBusy: false,
      isLoading: false,
      tableItems: [],
      tableColumns: [],
      showPerPage: false,
      saveReportType: null,
      groupBy: '',
      groupByOptions: [],
      groupedData: [],
      selectedSchool: null,
    }
  },
  setup() {
    const {
      fetchFields,
      reportExecute,
      fetchReport,
      postReport,
      updateReport,
    } = useReportDetails()

    return {
      fetchFields,
      reportExecute,
      fetchReport,
      postReport,
      updateReport,
    }
  },
  computed: {
    isTableUpdateAllowed() {
      return !!this.selectedSchool
    },
    programId() {
      return store.getters['verticalMenu/getDefaultProgram']
    },
    dataMeta() {
      const localItemsCount = this.$refs.reportTable ? this.$refs.reportTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalItems,
      }
    },
  },
  watch: {
    perPage() {
      this.updateTableData()
    },
    currentPage() {
      this.updateTableData()
    },
    selectedFields() {
      if (this.groupBy) {
        const groupByField = this.groupBy.replace(/`/g, '')
        const groupByFieldindex = this.selectedFields.findIndex(item => item.value === groupByField)
        if (groupByFieldindex !== -1) {
          this.groupBy = ''
        }
      }
    },
    groupBy(val) {
      if (val) {
        const field = val.replace(/`/g, '')
        const index = this.selectedFields.findIndex(item => item.value === field)
        if (index !== -1) {
          this.selectedFields.splice(index, 1)
        }
      }
    },
  },
  async created() {
    if (this.reportId) {
      this.isLoading = true
      await this.fetchReport(this.reportId)
        .then(response => {
          this.reportName = response.name
          this.reportView = response.reportView
          this.filters = response.filters
          this.groupBy = response.group_by

          this.setReportViewFields(response.reportView)
            .then(() => {
              this.setSelectedFields(response.selected_fields)
            })
        })
        .finally(() => {
          this.isLoading = false
        })
    } else {
      this.reportName = 'New Report'
    }
  },
  mounted() {
    if (!this.reportId) {
      this.openReportViewModal()
    }
  },
  methods: {
    checkIfSlotValueIsBase64File(item) {
      if (item) {
        const matches = item.toString().match(/^data:image\/(\w+);base64,/)
        if (!matches) {
          return false
        }
        return matches.length > 0
      }
      return false
    },
    showFilterModal() {
      this.$bvModal.show('filters-modal')
    },
    openReportViewModal() {
      this.$bvModal.show('report-view-list-modal')
    },
    setReportView(val) {
      this.$bvModal.hide('report-view-list-modal')
      this.reportView = val
      this.updateAllReportData(val)
    },
    async updateAllReportData(reportView) {
      this.resetReportViewFields()
      this.resetFilters()

      await this.setReportViewFields(reportView)
    },
    async setReportViewFields(reportView) {
      await this.fetchFields(reportView.id).then(res => {
        const result = res.fields.filter(field => !field.value.includes('ppa.'))
        this.reportViewFields = result

        this.fieldsOptions = result.map(field => ({ value: `\`${field.value}\``, text: `${field.label}` }))
      })

      if (this.$route.query.program_id) {
        this.selectAll()
      }
    },
    selectAll() {
      this.selectedFields = [...this.reportViewFields]
    },
    deselectAll() {
      this.selectedFields = []
    },
    removeFieldFromSelected(index) {
      this.selectedFields.splice(index, 1)
    },
    async selectAllFields() {
      this.reportViewFields.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.selectedFields = item.fields
      })
    },
    setSelectedFields(selectedFields) {
      const fields = []
      selectedFields.forEach(item => {
        const field = this.reportViewFields.find(el => el.value === item)
        if (field) {
          fields.push(field)
        }
      })
      this.selectedFields = [...fields]
    },
    resetReportViewFields() {
      this.reportViewFields = []
      this.selectedFields = []
      this.groupBy = ''
    },
    async updateTableData() {
      this.$refs.reportTable.refresh()
    },
    resetFilters() {
      this.filters = getDefaultFilters()
    },
    removeFilterRow(index) {
      this.filters.children.splice(index, 1)
    },
    removeFilterFromTable(index) {
      this.removeFilterRow(index)
    },
    async fetchTableList() {
      if (this.selectedFields.length === 0 && !this.isTableUpdateAllowed) return []
      this.isBusy = true
      this.groupedData = []
      try {
        if (this.$route.query.program_id) {
          // eslint-disable-next-line array-callback-return
          const isExist = this.filters.children.filter(item => item.query.rule === '`programs.id`')
          if (isExist.length === 0) {
            this.filters.children.push({
              type: 'query-builder-rule',
              query: {
                rule: '`programs.id`',
                operator: 'equals',
                value: this.$route.query.program_id,
              },
            })
          }
        }
        const queryParams = {
          per_page: this.perPage,
          page: this.currentPage,
          report_view_id: this.reportView.id,
          selected_fields: this.selectedFields.map(field => field.value),
          filters: this.filters,
          school_id: this.selectedSchool,
        }

        if (this.sortBy) {
          queryParams.order_column = `\`${this.sortBy}\``
          queryParams.order_column_dir = this.isSortDirDesc ? 'desc' : 'asc'
        }

        if (this.groupBy) {
          queryParams.group_by = this.groupBy
        }

        const response = await this.reportExecute(queryParams)
        this.totalItems = response.data.total
        this.showPerPage = this.totalItems > 0

        let items = []
        if (this.totalItems > 0 && response.data?.data[0]) {
          // eslint-disable-next-line no-underscore-dangle
          if (response.data?.data[0]?.group_by) {
            this.groupByOptions = response.data.data[0].group_by

            this.groupedData = response.data.data.map(item => {
              const grouped = item.group.map(el => ({
                ...el,
                [this.groupByOptions.name]: this.groupByOptions.value,
              }))
              return {
                ...item,
                grouped,
                collapsed: true,
              }
            })

            const columns = []
            Object.keys(this.groupedData[0].grouped[0]).forEach(key => {
              if (key !== this.groupByOptions.name) {
                columns.push(key)
              }
            })
            this.tableColumns = columns
            this.tableColumns.unshift(this.groupByOptions.name) // add grouped by col
            items = response.data.data
          } else {
            this.tableColumns = Object.keys(response.data.data[0])
            items = response.data.data.map(item => ({
              ...item,
            }))
          }
        }
        return items
      } catch (error) {
        return []
      } finally {
        this.isBusy = false
      }
    },
    async exportReport() {
      const queryParams = {
        report_view_id: this.reportView.id,
        selected_fields: this.selectedFields.map(field => field.value),
        filters: this.filters,
      }

      if (this.sortBy) {
        queryParams.order_column = `\`${this.sortBy}\``
        queryParams.order_column_dir = this.isSortDirDesc ? 'desc' : 'asc'
      }

      await this.reportExport(queryParams).then(response => {
        const filename = `${this.reportView.name}-export`
        this.downloadFile(response.data, filename.replace(/\s/g, '-'), 'csv')
      })
    },
    openSaveReportModal(isSaveAs) {
      this.saveReportType = isSaveAs ? 'saveAs' : 'save'
      this.$bvModal.show('save-report-modal')
    },
    async saveReport(name) {
      const queryParams = {
        name,
        report_view_id: this.reportView.id,
        selected_fields: this.selectedFields.map(field => field.value),
        filters: this.filters,
        group_by: this.groupBy,
        is_default_report: true,
      }

      if (this.saveReportType === 'saveAs') {
        await this.postReport(queryParams).then(() => {
          this.$router.push({ name: 'admin-reports' })
        })
        return
      }

      if (this.reportId) {
        await this.updateReport(this.reportId, queryParams).then(() => {
          this.$router.push({ name: 'admin-reports' })
        })
      } else {
        await this.postReport(queryParams).then(() => {
          this.$router.push({ name: 'admin-reports' })
        })
      }
    },
    sortByColumn(column) {
      this.sortBy = column
      this.isSortDirDesc = !this.isSortDirDesc
      this.updateTableData()
    },
    sortForGroupedData(column) {
      this.sortBy = column
      this.isSortDirDesc = !this.isSortDirDesc
      this.groupedData.forEach(el => {
        const sorted = el.grouped.sort(dynamicSort(this.sortBy, this.isSortDirDesc))
        // eslint-disable-next-line no-param-reassign
        el.grouped = sorted
      })
    },
    getHeaderHeight(refName) {
      if (this.$refs[refName] !== undefined && this.$refs[refName].length > 0) {
        return `${this.$refs[refName][0].clientHeight}px`
      }
      return '60px'
    },
    getContainerWith() {
      if (this.$refs.groupedTablesContainer !== undefined) {
        return `${this.$refs.groupedTablesContainer.scrollWidth}px`
      }
      return '100%'
    },
    toggleGroupedTableCollapse(index) {
      this.groupedData[index].collapsed = !this.groupedData[index].collapsed
    },
    collapseAll() {
      this.groupedData.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.collapsed = true
      })
    },
    expandAll() {
      this.groupedData.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.collapsed = false
      })
    },
    selectSchool(schoolId) {
      this.selectedSchool = schoolId
      this.updateTableData()
    },
  },
}
</script>

<style lang="scss" scoped>
  .overflow {
    overflow-x: scroll;
  }
  .table-header {

    label {
      margin-bottom: 0;
    }
  }
  .per-page-selector {
    width: 90px;
  }
  .break-word{
    word-break: break-all;
  }
  .image-slot {
    max-height: 5vh;
  }
  .stiky-rowspan {
    position: sticky;
    top: 60px;
    z-index: 3;
    &__value {
      font-weight: 400;
    }
    &__total {
      font-weight: 600;
    }
    &__collapse {
      position: absolute;
      right: -10px;
      top: 10px;
    }
  }
  .badge-selected-column {
    padding: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    span {
      margin-left: 5px;
    }
    .close-icon {
      position: absolute;
      top: calc(50% - 6px);
      right: 5px;
    }
  }
  .grouped-tables-container {
    overflow-x: auto;
  }
</style>
