<template>
  <b-modal
    id="filters-modal"
    ref="filters-modal"
    title="Filters"
    centered
    no-close-on-backdrop
    ok-title="Apply"
    cancel-variant="outline-secondary"
    size="lg"
    class="p-0"
    @ok="applyFilters"
  >
    <b-row>
      <b-col
        cols="3"
      >
        <b-form-group
          label="Logical Operator"
        >
          <b-form-select
            v-model="filters.logicalOperator"
            :options="logicalOperatorOptions"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <FilterRow
      v-for="(filter, index) in filters.children"
      :key="index"
      :filter="filter"
      :fields-options="fieldsOptions"
      :operator="filters.logicalOperator"
      :index="index"
      class="cursor-default"
      @removeFilterRow="removeFilterRow"
    />
    <!-- Add Button -->
    <div
      class="d-flex align-items-center justify-content-center"
    >
      <b-button
        variant="outline-primary"
        class="d-flex align-items-center justify-content-center"
        @click="addNewFilter"
      >
        <feather-icon
          icon="PlusCircleIcon"
          size="25"
          class="mr-1 cursor-pointer"
        />
        Add filter
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BRow, BCol, BFormGroup, BFormSelect,
} from 'bootstrap-vue'

import FilterRow from '@/views/admin/reports-v2/reports/report-details/filters-modal/FilterRow.vue'

export default {
  components: {
    BModal,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,

    FilterRow,
  },
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    fieldsOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      logicalOperatorOptions: [
        { value: 'and', text: 'And' },
        { value: 'or', text: 'Or' },
      ],
    }
  },
  methods: {
    addNewFilter() {
      this.filters.children.push({
        type: 'query-builder-rule',
        query: {
          rule: '',
          operator: '',
          value: '',
        },
      })
    },
    removeFilterRow(index) {
      this.$emit('removeFilterRow', index)
    },
    applyFilters() {
      this.$emit('updateTable')
    },
  },
}
</script>

<style>

</style>
