<template>
  <b-card
    class="mb-1"
  >
    <b-row
      align-v="center"
    >
      <b-col
        cols="1"
      >
        <span>{{ operator }}</span>
      </b-col>
      <b-col
        cols="4"
      >
        <b-form-group
          label="Field"
        >
          <v-select
            v-model="filter.query.rule"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="fieldsOptions"
            label="text"
            :reduce="option => option.value"
            :clearable="false"
            placeholder="Please select a filter"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="3"
      >
        <b-form-group
          label="Operator"
        >
          <b-form-select
            v-model="filter.query.operator"
            :options="operatorsOptions"
          >
            <template #first>
              <b-form-select-option
                :value="''"
                disabled
              >
                Please select a operator
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col
        v-if="isDateOperator"
        cols="3"
      >
        <b-form-group
          label="Value"
        >
          <flat-pickr
            v-model="filter.query.value"
            class="form-control"
          />
        </b-form-group>
      </b-col>

      <b-col
        v-else
        cols="3"
      >
        <b-form-group
          label="Value"
        >
          <b-form-input
            v-model="filter.query.value"
            :disabled="isOperatorWithoutValue"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="1"
        class="d-flex justify-content-end"
      >
        <feather-icon
          icon="Trash2Icon"
          size="25"
          class="cursor-pointer"
          @click="removeFilterRow(index)"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BFormSelect, BFormSelectOption, BFormInput, BFormGroup, BRow, BCol, BCard,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BFormInput,
    BCol,
    BRow,
    BCard,

    flatPickr,
    vSelect,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    fieldsOptions: {
      type: Array,
      default: () => [],
    },
    operator: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      operatorsOptions: [
        { value: 'equals', text: 'equals' },
        { value: 'does_not_equal', text: 'does not equal' },
        { value: 'greater_than', text: 'greater than' },
        { value: 'greater_than_or_equal', text: 'greater than or equal' },
        { value: 'less_than', text: 'less than' },
        { value: 'less_than_or_equal', text: 'less than or equal' },
        { value: 'like', text: 'like' },
        { value: 'not_like', text: 'not like' },
        { value: 'is_at', text: 'is at' },
        { value: 'is_before', text: 'is before' },
        { value: 'is_before_or_at', text: 'is before or at' },
        { value: 'is_after', text: 'is after' },
        { value: 'is_after_or_at', text: 'is after or at' },
        { value: 'is_not_at', text: 'is not at' },
        { value: 'is_blank', text: 'is blank' },
        { value: 'is_not_blank', text: 'is not blank' },
        { value: 'is_today', text: 'is today' },
        { value: 'is_this_week', text: 'is this week' },
        { value: 'is_this_month', text: 'is this month' },
        { value: 'is_this_year', text: 'is this year' },
        { value: 'is_last_month', text: 'is last month' },
        { value: 'is_next_month', text: 'is next month' },
      ],
      typeOptions: ['and', 'or'],
    }
  },
  computed: {
    isDateOperator() {
      return ['is_at', 'is_before', 'is_before_or_at', 'is_after', 'is_after_or_at', 'is_not_at'].includes(this.filter.query.operator)
    },
    isOperatorWithoutValue() {
      return [
        'is_blank',
        'is_not_blank',
        'is_today',
        'is_this_week',
        'is_this_month',
        'is_this_year',
        'is_last_month',
        'is_next_month',
      ].includes(this.filter.query.operator)
    },
  },
  methods: {
    removeFilterRow(index) {
      this.$emit('removeFilterRow', index)
    },
  },
}
</script>
<style lang="scss" scoped>
svg {
  color: #7366F0;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
